<script lang="ts" setup>
import { store } from '@/store/store';
const Store = store()
</script>

<template>
    <div class="banner container" ref="target">
        <div class="top-cont">
            <p class="title">
            {{ Store.getCurrentLang == "GE" ? "გაწევრიანდი Discord სერვერზე!" : "Join Discord server!" }}
            </p>
            <a href="https://discord.com/invite/2CuqQ9HNu4" target="__blank">
                <img src="@/assets/dc-join.png" alt="Join image" />
            </a>
        </div>
        <div class="top-cont">
            <p class="title">
                {{ Store.getCurrentLang == "GE" ? "გამოიწერე YouTube არხი!" : "Subscribe YouTube channel!" }}
            </p>
            <a href="https://www.youtube.com/@Jortsoft" target="__blank">
                <img src="@/assets/yt-sub.png" alt="Join image" />
            </a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/responsive.scss";
@import "../styles/mixins.scss";

.banner {
    position: relative;

    .top-cont {
        width: 727px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 0 auto;
        padding-bottom: 120px;

        @include responsive(tablet) {
            width: 100%;
        }

        @include responsive(mobile-large) {
            padding-bottom: 50px;
        }

        &:first-child {
            padding-top: 120px;

            @include responsive(mobile-large) {
                padding-top: 50px;
            }
        }

        img {
            width: 250px;
            transition: 0.4s;
            @include hover() {
                transform: scale(1.1);
                filter: drop-shadow(2px 4px 6px black);

            }
            @include responsive(mobile-large) {
                width: 230px;
            }
        }

        .title {
            font-size: 48px;
            text-align: center;
            margin-bottom: 40px;
            position: relative;

            @include responsive(mobile-large) {
                font-size: 34px;
            }
        }
    }
}
</style>