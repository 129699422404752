import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import VueLazyLoad from 'vue3-lazyload'
import './styles/general.scss'
import { createHead } from '@vueuse/head'

const app = createApp(App);
const pinia = createPinia();
const head = createHead();

app.use(router);
app.use(pinia);
app.use(head);
app.use(VueLazyLoad, {});
app.mount('#app')
