<script lang="ts" setup>
import { store } from '@/store/store';

const Store = store();
</script>

<template>
    <div class="not-found">
        <img src="@/assets/404.png" alt="Img" />
        <p class="title">{{ Store.getCurrentLang == "GE" ? 'გვერდი ვერ მოიძებნა' : 'Page not found' }} :(</p>
    </div>
</template>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/responsive.scss";

.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 140px;
    height: 100vh;
    position: relative;
    img {
        margin-bottom: 20px;
        width: 400px;
        @include responsive(mobile-large) {
            width: 200px;
        }
    }
    .title {
        font-size: 48px;
        text-align: center;
        color: $white;
        @include responsive(mobile-large) {
            font-size: 32px;
        }
    }
    @include responsive(tablet) {
        padding-top: 80px;
    }
}
</style>