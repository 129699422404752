import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import NewsPage from '../views/NewsPage.vue'
import ProjectPage from '../views/ProjectPage.vue'
import NotFound from '../views/NotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomePage,
  },
  {
    path: '/home',
    name: 'home-real',
    component: HomePage,
  },
  {
    path: '/videos',
    name: 'videos',
    component: NewsPage,
  },
  {
    path: '/project/:projectname',
    name: 'project',
    component: ProjectPage,
  },
  {
    path: '/:notFound',
    name: 'not-found',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
