<script lang="ts" setup>
import { store } from '@/store/store';

const Store = store();

</script>

<template>
    <div class="support container">
        <div class="content">
            <p class="title">{{ Store.getCurrentLang == 'GE' ? 'შემოდი Patreon-ზე!' : 'Join Patreon' }}</p>
            <p class="text" v-if="Store.getCurrentLang == 'GE'">
                Patreon არის იდეალური გზა, რითაც შეგიძლია მხარი დამიჭირო და თან მიიღო სარგებელი.
                <br />
                <br />
                გადახედე ფასიან <a href="https://www.patreon.com/jortsoft/membership" target="__blank">პაკეტებს</a> და
                ისარგებლო ბენეფიტებით.
            </p>
            <p class="text" v-else>
                Patreon is the perfect way for you to support me and benefit from it.
                <br />
                <br />
                review the paid <a href="https://www.patreon.com/jortsoft/membership" target="__blank">packages</a> and
                enjoy the benefits.
            </p>
            <a href="https://www.patreon.com/jortsoft/posts" class="join" target="__blank">
                <img src="@/assets/patreon_join.png" width="200px" alt="Join Button" />
            </a>
        </div>
        <div class="content">
            <p class="title">{{ Store.getCurrentLang == 'GE' ? 'დონაცია' : 'Donation' }}</p>
            <p class="text" v-if="Store.getCurrentLang == 'GE'">
                თუ უბრალოდ დონაციის გაკეთება გინდა, შეგიძლია დაადონატო რამდენიც გაგიხარდება.
                <br />
                <br />
                შენი სახელი გამოჩნდება ტოპ დოანტორებში სტრიმზე.
                თანხა მთლიანად მოხმარდება არხის განვითარებას ♥
            </p>
            <p class="text" v-else>
                If you just want to donate, you can donate as much as you like.
                <br />
                <br />
                Your name will appear in the top donators on stream.
                The money will be used entirely for the development of the channel ♥
            </p>
            <a href="https://www.donationalerts.com/r/jortsoft666" class="join" target="__blank">
                <img src="@/assets/donate.png" width="200px" alt="Join Button" />
            </a>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/responsive.scss";
@import "../styles/mixins.scss";

.support {
    position: relative;
    padding-top: 90px;
    padding-bottom: 150px;

    @include responsive(mobile-large) {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .content {
        width: 1150px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 auto;

        @include responsive(desktop-small) {
            width: 100%;
        }

        &:nth-child(2) {
            padding-top: 120px;

            @include responsive(mobile-large) {
                padding: 50px;
            }
        }

        .join {
            display: flex;
            margin: 0 auto;
            padding-top: 40px;
            cursor: pointer;
            transition: 0.4s;
            backface-visibility: hidden;

            @include hover() {
                transform: scale(1.1);
                filter: drop-shadow(2px 4px 6px black);
            }
        }

        .title {
            text-align: center;
            font-size: 48px;
            margin-bottom: 20px;

            @include responsive(mobile-large) {
                font-size: 32px;
            }
        }

        .text {
            font-size: 36px;
            text-align: center;

            @include responsive(mobile-large) {
                font-size: 24px;
                text-align: center;
            }

            a {
                text-decoration: underline;
                color: $orange;
            }
        }
    }
}
</style>