<script lang="ts" setup>
import news from '@/jsons/news.json'
import { store } from '@/store/store';
import { ComputedRef, Ref, computed, ref } from 'vue';
import { device } from "@/modules/breakpoints"
import Footer from '@/components/Footer.vue';
import {newsModel} from '@/models/newsModel'
import { useHead } from '@vueuse/head'

const Store = store();

const searchText: Ref<string> = ref('');
const increaseNum: number = 9;
const sliceNum: Ref<number> = ref(9);

const sortedData:ComputedRef<newsModel[]> = computed(() => {
    const sortedData = news.filter(item => item.title.toLocaleLowerCase().includes(searchText.value.toLocaleLowerCase()));
    if (searchText.value.length > 3) {
        return sortedData.slice(0, sliceNum.value);
    } else {
        return news.slice(0, sliceNum.value)
    }
})

const loadMore = (): void => {
    sliceNum.value += increaseNum;
}

useHead({
  title: "Jortsoft videos",
  meta: [
    {
      name: "description",
      content: "Jortsoft videos page"
    },
    {
      property: "og:url",
      content: Store.getDomain
    },
    {
      property: "og:type",
      content: "website"
    },
    {
      property: "og:title",
      content: "Jortsoft videos page"
    },
    {
      property: "og:description",
      content: "Jortsoft videos page"
    },
    {
      property: "og:image",
      content: "https://opengraph.b-cdn.net/production/documents/e75df412-75d0-4e3b-8af8-d947120743b6.jpg?token=YD4SwTdd2E04mG9TgECpm4VrgAZB4VBEPf9T9_clrOA&height=675&width=1200&expires=33240107303"
    },
  ]
})
const getIframeUrl = (url: any): any => {
    return url.match(/watch\?v=([^&]+)/)[1];
}

</script>

<template>
    <div class="news-page">
        <div class="top-cont center">
            <p class="title">{{ Store.getCurrentLang == "GE" ? "ვიდეოები" : "Videos"  }}</p>
            <div class="inp">
                <input type="text" v-model="searchText" :placeholder="Store.getCurrentLang == 'GE' ? 'ძებნა...' : 'Search...'" />
            </div>
        </div>
        <div class="content container">
            <p class="not-found" v-if="!sortedData.length">Not Found data</p>
            <div class="box" v-for="(item, index) in sortedData" :key="index">
                <div @click="Store.openVideoPopup(getIframeUrl(item.video))">
                <div class="img" @mouseenter="Store.setActiveMouse(true)" @mousemove="Store.setActiveMouse(true)"
                    @mouseleave="Store.setActiveMouse(false)">
                    <img class="abs-img"
                        v-lazy="{ src: Store.getWindowWidth <= device.tablet ? item.thumbMobile : item.thumbDesktop, loading: require('@/assets/loading.gif') }"
                        alt="Thumbnail" />
                </div>
            </div>
                <p class="title">
                    {{ item.title }}
                </p>
            </div>
        </div>
        <button class="center pointer" v-if="sortedData.length && sliceNum <= news.length && searchText.length < 3" @click="loadMore()">
            <p>{{ Store.getCurrentLang == 'GE' ? 'მეტი' : 'More' }}</p>
        </button>
        <Footer />
    </div>
</template>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/responsive.scss";
@import "../styles/mixins.scss";

.news-page {
    padding-top: 140px;
    position: relative;

    button {
        width: 216px;
        height: 39px;
        border-radius: 10px;
        border: 2px solid $black;
        background: $yellow;
        font-size: 20px;
        display: flex;
        margin: 0 auto;
        margin-bottom: 100px;
        margin-top: 50px;
        transition: 0.4s;
        @include responsive(mobile-large) {
            margin-bottom: 50px;
            margin-top: 20px;
        }
        @include hover {
            transform: scale(1.1);
            box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        }
    }

    @include responsive(tablet) {
        padding-top: 80px;
    }

    position: relative;

    .top-cont {
        flex-direction: column;
        padding-top: 35px;

        .inp {
            position: relative;
            width: 385px;
            height: 54px;
            overflow: hidden;
            border-radius: 5px;
            border: 3px solid $black;

            @include responsive(mobile-large) {
                width: 300px;
                height: 45px;
            }

            input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                font-size: 20px;
                padding: 18px;
                background: transparent;
                @include responsive(mobile-large) {
                    font-size: 16px;
                }
            }
        }

        .title {
            font-size: 48px;
            margin-bottom: 26px;
            @include responsive(mobile-large) {
                font-size: 36px;
            }
        }
    }

    .content {
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;

        .not-found {
            font-size: 34px;
            text-align: center;
            display: flex;
            margin: 0 auto;
            margin-bottom: 50px;
        }

        .box {
            width: calc(33.33% - 54px);
            position: relative;
            margin-bottom: 25px;
            margin-right: 80px;

            &:nth-child(3n) {
                margin-right: 0px;
            }

            @include responsive(desktop-medium) {
                margin-right: 50px;
                width: calc(33.33% - 34px);
            }

            @include responsive(desktop-small) {
                margin-right: 35px;
                width: calc(33.33% - 24px);
            }

            @include responsive(tablet) {
                width: calc(50% - 18px);

                &:nth-child(2n) {
                    margin-right: 0px !important;
                }

                &:nth-child(3n) {
                    margin-right: 35px;
                }
            }

            @include responsive(mobile-large) {
                width: 100%;
                margin-right: 0px;

                &:nth-child(3n) {
                    margin-right: 0px;
                }
            }

            .title {
                font-size: 32px;
                margin-top: 13px;
                @include splitText(2);

                @include responsive(desktop-small) {
                    font-size: 24px;
                }

                @include responsive(tablet) {
                    font-size: 18px;

                }

                @include responsive(mobile-large) {}
            }

            .img {
                position: relative;
                width: 100%;
                padding-bottom: 55%;
                border: 6px solid $black;
                overflow: hidden;
                img {
                    transition: 0.4s;
                    backface-visibility: hidden;
                }
                @include hover {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
}
</style>