<script setup lang="ts">
import Swiper from 'swiper';
import { onMounted, ref } from 'vue';
import { store } from '@/store/store';
import {comercialProjectModel} from '../models/comercialProjectsModel'

const props = defineProps<{
    title: string,
    data: comercialProjectModel[]
}>();

const Store = store();

const mySwiper = ref();

const initSwiper = (): void => {
    mySwiper.value = new Swiper('.project-slider .swiper-container', {
        // Configure Swiper options here
        slidesPerView: 3.2,
        spaceBetween: 27,
        speed: 800,
        watchOverflow: true,
        resizeObserver: true,
        breakpoints: {
            320: {
                slidesPerView: 1.2,
                spaceBetween: 15
            },
            767: {
                slidesPerView: 2.2,
                spaceBetween: 20
            },
            1023: {
                slidesPerView: 3.2,
                spaceBetween: 27,
            }
        }
    });
    mySwiper.value.init();
    mySwiper.value.on('sliderMove', () => {
        Store.setActiveMouse(false)
    })
}

onMounted(() => {
    initSwiper();
})
</script>

<template>
    <div class="project-slider">
        <div class="top-cont">
            <p class="title">{{ props.title }}</p>
        </div>
        <div class="swiper-container">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in props.data" :key="index">
                    <a :href="item.link" target="__blank">
                    <div class="box" @mouseenter="Store.setActiveMouse(true)" @mouseleave="Store.setActiveMouse(false)">
                        <img v-lazy="{ src: item.img, loading: require('@/assets/loading.gif')}" class="abs-img" alt="Project Image" />
                        <div class="mask"></div>
                        <p>{{ item.name }}</p>
                    </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/responsive.scss";
@import "../styles/mixins.scss";

.project-slider {
    margin-top: 50px;
    @include responsive(mobile-large) {
        margin-top: 20px;
    }
    .top-cont {
        margin-bottom: 12px;

        .title {
            font-size: 24px;
            @include responsive(mobile-large) {
                font-size: 18px;
            }
        }
    }

    .swiper-container {
        .box {
            position: relative;
            padding-bottom: 52%;
            border: 5px solid $black;
            border-radius: 5px;
            overflow: hidden;

            @include hover() {
                .mask {
                    opacity: 1;
                }

                p {
                    opacity: 1;
                    transform: translateY(0px);
                }
            }

            p {
                position: absolute;
                bottom: 10px;
                left: 10px;
                font-size: 24px;
                color: $white;
                opacity: 0;
                transform: translateY(30px);
                transition: 0.4s;
                @include responsive(tablet) {
                    opacity: 1;
                    transform: translateY(0px);
                }
                @include responsive(mobile-large) {
                    font-size: 18px;
                }
            }

            .mask {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 68px;
                background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 80.06%);
                opacity: 0;
                transition: 0.4s;
                @include responsive(tablet) {
                    opacity: 1;
                }
            }
        }
    }
}</style>