<script setup lang="ts">
import aboutGEO from '@/jsons/aboutGEO.json'
import aboutENG from '@/jsons/aboutENG.json'
import projects from '@/jsons/comercialProjects.json'
import { store } from '@/store/store';
import ProjectSlider from './ProjectSlider.vue';

const Store = store();
</script>

<template>
    <div class="about container">
        <div class="top-cont">
            <div class="left-side">
                <img v-lazy="{ src: require('@/assets/me.webp'), loading: require('@/assets/loading.gif') }"
                    class="abs-img" alt="Person" />
            </div>
            <div class="right-side">
                <p class="title">{{ Store.getCurrentLang == "GE" ? aboutGEO.title : aboutENG.title }}</p>
                <span v-for="(item, index) in aboutGEO.stats" :key="index" v-if="Store.getCurrentLang == 'GE'">
                    {{ item.name }} <p>{{ item.val }}</p>
                </span>
                <span v-for="(item, i) in aboutENG.stats" :key="i" v-else>
                    {{ item.name }} <p>{{ item.val }}</p>
                </span>

                <p class="about-title">{{ Store.getCurrentLang == "GE" ? aboutGEO.aboutTitle : aboutENG.aboutTitle }}
                </p>
                <p class="desc" v-html="Store.getCurrentLang == 'GE' ? aboutGEO.description : aboutENG.description">
                </p>
            </div>
        </div>
        <ProjectSlider :title="Store.getCurrentLang == 'GE' ? 'კომერციული პროექტები' : 'Comercial projects'"
            :data="projects" />
    </div>
</template>

<style lang="scss" scoped>
@import "../styles/variables.scss";
@import "../styles/responsive.scss";

.about {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 90px;
    padding-bottom: 90px;
    position: relative;
    margin-top: -1px;

    @include responsive(tablet) {
        padding: 20px;
    }

    @include responsive(mobile-large) {
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .top-cont {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        @include responsive(tablet) {
            flex-direction: column;
        }

        .left-side {
            position: relative;
            width: 384px;
            height: 430px;
            border-radius: 10px;
            border: 5px solid $black;
            margin-right: 37px;
            flex: none;
            overflow: hidden;

            @include responsive(tablet) {
                width: 100%;
                margin-right: 0px;
                margin-bottom: 20px;
                height: unset;
                padding-bottom: 100%;
            }
        }

        .right-side {
            .title {
                font-size: 48px;

                @include responsive(mobile-large) {
                    font-size: 21px;
                }
            }

            .about-title {
                margin-top: 20px;
                font-size: 32px;
                margin-bottom: 5px;

                @include responsive(mobile-large) {
                    font-size: 21px;
                }
            }

            .desc {
                font-size: 20px;
                width: 900px;

                @include responsive(mobile-large) {
                    font-size: 16px;
                }

                @include responsive(desktop-medium) {
                    width: unset;
                }

                ::v-deep {

                    a {
                        color: $skyBlue;
                        cursor: pointer;
                    }
                }
            }

            span {
                font-size: 24px;
                display: flex;
                margin-bottom: 5px;

                @include responsive(desktop-small) {
                    flex-direction: column;
                    margin-bottom: 10px;
                    font-size: 18px;
                }

                p {
                    text-decoration: underline;
                    padding-left: 5px;

                    @include responsive(desktop-small) {
                        padding-left: 0px;
                    }
                }
            }
        }
    }
}
</style>